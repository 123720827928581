import { useChatListStore } from "@/stores/chatList";
import { computed, nextTick, ref, watch } from "vue";
import { defineStore } from "pinia";

import ChatService from "@/services/Chat";
import { groupMessagesByChannelHistory } from "@/utils";
import { useAuthStore } from "@/stores/userAuth";
import { useRouter } from "vue-router";
import type { Channel, Message, QuoteState } from "@/types/api/data-contracts";
import type { GroupedMessages } from "@/types";
import type { IMessageData } from "@/types/Notification";
import { INQUIRY_ICON_FALLBACK } from "@/constants";

export const useChatBoxStore = defineStore(
    "chatBoxStore",
    () => {
        const authStore = useAuthStore();
        const { markChannelAsRead } = useChatListStore();
        const router = useRouter();
        const chatService = ChatService.getInstance();

        const visible = ref(false);
        const expanded = ref(false);
        const minimized = ref(false);

        const isVisible = computed(() => visible.value);
        const isExpanded = computed(() => expanded.value);
        const isMinimized = computed(() => minimized.value);

        const messages = ref<Message[]>([]);
        const channelId = ref();
        const channelDetails = ref<Channel>();

        const productName = ref();
        const logo = ref();
        const chatRelObjectStatus = ref();
        const isRecurring = ref();
        const attachment = ref();
        const fileName = ref("");
        const contactName = ref("");
        const submitted = ref(false);
        const loading = ref(false);

        const canFetchMoreMessages = ref(false);
        const currentPage = ref(1);
        const currentMessageUuid = ref<string | undefined>();
        const fetchingMessages = ref(false);

        const chatList = ref<HTMLElement>();

        /**
         * increment the value of current page by 1
         * once user has reached at the top of chat-box
         */
        const incrementCurrentPage = () => {
            if (canFetchMoreMessages.value) {
                currentPage.value += 1;
            }
        };

        /**
         * Decrement the value of current page by 1
         * once user has reached at the bottom of chat-box
         */
        const decrementCurrentPage = () => {
            if (currentPage.value > 1) {
                currentPage.value -= 1;
            }
        };

        /**
         * Setter for the current page of the chatbox store.
         * @param page
         */
        const setCurrentPage = (page: number) => {
            currentPage.value = page;
        };

        /**
         * Index of the message to be highlighted in the list of messages loaded.
         * @param {string} uuid
         */
        const setCurrentMessageUuid = (uuid: string) => {
            currentMessageUuid.value = uuid;
        };

        const setChannelId = (newChannelId: string) => {
            channelId.value = newChannelId;
        };

        /**
         * Setter for logo reactive property
         * @param {string} newLogo - The logo URL
         */
        const setLogo = (newLogo: string | undefined) => {
            logo.value = newLogo;
        };

        /**
         * Setter for productName reactive property
         * @param {newProductName} newLogo - The product name
         */
        const setProductName = (newProductName: string) => {
            productName.value = newProductName;
        };

        /**
         * Setter for isRecurring reactive property
         * @param {boolean} isRecurringQuote
         */
        const setIsRecurring = (isRecurringQuote: boolean) => {
            isRecurring.value = isRecurringQuote;
        };

        /**
         * setter for contactName
         * @param contactNameParam
         */
        const setContactName = (contactNameParam: string) => {
            contactName.value = contactNameParam;
        };

        /**
         * Setter for the realObjectStatus reactive property.
         * @param {string | undefined} realObjectStatus - The status of the object (quote, contract, order)
         */
        const setRealObjectStatus = (
            realObjectStatus: `${QuoteState}` | undefined
        ) => {
            chatRelObjectStatus.value = realObjectStatus;
        };

        const rolledUpMessages = computed(() => {
            if (messages.value.length > 0 && channelDetails.value) {
                return groupMessagesByChannelHistory(
                    messages.value,
                    channelDetails.value.object_history,
                    canFetchMoreMessages.value
                );
            }
            return messages.value;
        }) as unknown as GroupedMessages;

        const logoUrl = computed(() => logo.value ?? INQUIRY_ICON_FALLBACK);

        const chatType = computed(() => {
            if (
                [
                    "declined",
                    "expired",
                    "available",
                    "contract_created",
                    "contract_proceeded",
                    "proceeded",
                ].includes(chatRelObjectStatus.value)
            ) {
                return "Quote";
            }

            if (!isRecurring.value) {
                return "Order";
            }

            return "Contract";
        });

        const relatedObjectType = computed(
            () => channelDetails.value?.related_object_type
        );

        const relatedObjectUUID = computed(
            () => channelDetails.value?.related_object_uuid
        );

        const chatTitle = computed(() => {
            if (relatedObjectType.value === "inquiry") {
                return `Request for ${productName.value ?? "Name"} - ${
                    contactName.value
                }`;
            }
            return `${
                relatedObjectType.value || chatType.value || "Chat"
            } for ${productName.value ?? "Name"}`;
        });

        const link = computed(() => {
            const side = authStore.isBrand ? "brands" : "suppliers";

            if (relatedObjectType.value === "quote") {
                return `/${side}/quotes/details/${relatedObjectUUID.value}`;
            }

            if (relatedObjectType.value === "order") {
                return `/${side}/orders/details/${relatedObjectUUID.value}`;
            }

            if (relatedObjectType.value === "inquiry") {
                return `/${side}/requests/details/${relatedObjectUUID.value}`;
            }

            return `/${side}/contracts/details/quote/${relatedObjectUUID.value}/`;
        });

        const fetchMoreIfChatExpanded = () => {
            if (expanded.value && messages.value.length <= 10) {
                incrementCurrentPage();
            }
        };

        /**
         * handle the expand action of the chat box
         * if the chat box is minimized then set minimized and expand to false to show default chat box
         * else toggle the expanded prop to toggle b/w default & expanded version of chat box
         */
        const expandChatBox = () => {
            if (minimized.value) {
                expanded.value = false;
                minimized.value = false;
            } else {
                expanded.value = !expanded.value;
            }
        };

        /**
         * handle minimize feature of chat box
         * if chat box is expanded set expanded to false to hide the wrapper
         * and set minimized to true
         */
        const minimizeChatBox = () => {
            if (isExpanded.value) {
                expanded.value = false;
            }
            minimized.value = true;
        };

        const showChat = () => {
            visible.value = true;
        };

        const goToLink = () => {
            router.push(link.value);
        };

        const handleFileUpload = (evt: any) => {
            const { files } = evt.target;
            const uploadedFile = files[0];
            fileName.value = uploadedFile.name;
            attachment.value = uploadedFile;
        };

        const fetchMessages = async (showLoader = true) => {
            if (showLoader) fetchingMessages.value = true;

            const scrollHeightBeforeLoadMoreMessages =
                chatList.value?.scrollHeight;

            const response = await chatService.getMessages(
                channelId.value,
                currentPage.value
            );

            const { results, next } = await response.data;
            canFetchMoreMessages.value = !!next;
            messages.value = [...results.reverse(), ...messages.value];

            if (channelDetails.value) {
                await markChannelAsRead(channelDetails.value);
            }

            fetchMoreIfChatExpanded();

            await nextTick();

            if (
                scrollHeightBeforeLoadMoreMessages !== undefined &&
                chatList.value
            )
                chatList.value.scrollTop =
                    chatList.value.scrollHeight -
                    scrollHeightBeforeLoadMoreMessages;

            if (showLoader) fetchingMessages.value = false;
        };

        const resetChatBoxStore = () => {
            localStorage.removeItem("chatBoxStore");
            visible.value = false;
            expanded.value = false;
            minimized.value = false;
            productName.value = "";
            logo.value = "";
            channelId.value = "";
            contactName.value = "";
        };

        const scrollToEndOfList = () => {
            if (chatList.value) {
                chatList.value.scrollTop = chatList.value.scrollHeight;
            }
        };

        /**
         * Retrieves the latest messages compares them to the messages state,
         * determines which messages have been added and returns them.
         * @param newMessages
         */
        // const getNewMessages = (newMessages: Message[]) => {
        //     const lastOldMessage = messages.value[messages.value.length - 1];

        //     const lastOldMessageCreated =
        //         lastOldMessage?.created || "2020-01-01T12:39:37+0000";

        //     return newMessages.filter(
        //         (message) => message.created > lastOldMessageCreated
        //     );
        // };

        /**
         * When a new message is sent or received
         * appends it to the messages array, if chat window is open
         * and if the message is of same channel.
         */
        const processNewMessage = async (message: IMessageData) => {
            if (isVisible.value && channelId.value === message.channel.uuid) {
                // transform the data
                const newMessage = {
                    channel: message.channel,
                    text: message.text,
                    attachment: message.attachment,
                    sender: message.sender,
                    is_read: message.is_read,
                    created: message.created,
                    modified: message.modified,
                } as Message;
                // append the new message
                messages.value.push(newMessage);
                await nextTick();
                scrollToEndOfList();
            }
        };

        const fetchChannelDetails = async (id: string) => {
            const response = await chatService.getChannelDetails(id);
            channelDetails.value = response.data;
        };

        watch(channelId, async (currChannelId) => {
            if (currChannelId) {
                loading.value = true;

                messages.value = [];
                canFetchMoreMessages.value = false;
                // currentPage.value = 1; @todo remove if not needed

                await fetchChannelDetails(currChannelId);
                await fetchMessages();
                loading.value = false;
            }
        });

        watch(currentPage, () => {
            if (canFetchMoreMessages.value) {
                fetchMessages(false);
            }
        });

        watch(expanded, (newValue) => {
            if (newValue) {
                document.body.style.overflow = "hidden";
            } else {
                document.body.style.overflow = "";
            }
        });

        return {
            fetchingMessages,
            incrementCurrentPage,
            decrementCurrentPage,
            setCurrentPage,
            setCurrentMessageUuid,
            processNewMessage,
            link,
            goToLink,
            resetChatBoxStore,
            expanded,
            minimized,
            visible,
            channelDetails,
            isRecurring,
            chatRelObjectStatus,
            chatType,
            chatTitle,
            productName,
            logo,
            contactName,
            logoUrl,
            handleFileUpload,
            attachment,
            fileName,
            submitted,
            loading,
            fetchMessages,
            channelId,
            messages,
            currentMessageUuid,
            isVisible,
            isExpanded,
            isMinimized,
            expandChatBox,
            minimizeChatBox,
            relatedObjectType,
            showChat,
            rolledUpMessages,
            scrollToEndOfList,
            chatList,
            setChannelId,
            setLogo,
            setProductName,
            setIsRecurring,
            setContactName,
            setRealObjectStatus,
        };
    },
    {
        persist: {
            paths: [
                "visible",
                "channelId",
                "minimized",
                "expanded",
                "productName",
                "chatType",
                "isRecurring",
                "logo",
                "contactName",
            ],
        },
    }
);
