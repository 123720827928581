import { ref } from "vue";
import { defineStore } from "pinia";
import type ICheckoutEvent from "@/types/CheckoutEvent";
import type ISaveCardEvent from "@/types/SaveCardEvent";

export const useCheckoutEventStore = defineStore(
    "CheckoutEventStore",
    () => {
        const data = ref({
            checkoutEvent: null as unknown as ICheckoutEvent,
            saveCardEvent: null as unknown as ISaveCardEvent,
        });

        const changeEvent = (newData: any) => {
            data.value.checkoutEvent = newData;
        };

        const updateSaveEvent = (newData: any) => {
            data.value.saveCardEvent = newData;
        };

        function $reset() {
            data.value = {
                checkoutEvent: {} as ICheckoutEvent,
                saveCardEvent: {} as ISaveCardEvent,
            };
        }

        return {
            data,
            changeEvent,
            updateSaveEvent,
            $reset,
        };
    },
    {
        persist: true,
    }
);
