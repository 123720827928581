import { vPopperThemes } from "@/config/vPopperThemes";
import { createApp } from "vue";

import PrimeVue from "primevue/config";
import ToastService from "primevue/toastservice";
import { createPinia } from "pinia";

import sentryInit from "@/sentry-init";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import FloatingVue from "floating-vue";

import { applyDirectives, testIdDirective } from "@/directives";

import App from "./App.vue";
import router from "./router";

// import styles
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "floating-vue/dist/style.css";
import "./assets/styles/global.scss";

const app = createApp(App);

sentryInit(app);

app.use(createPinia().use(piniaPluginPersistedstate));
app.use(router);
app.use(PrimeVue);
app.use(ToastService);

app.use(FloatingVue, {
    themes: vPopperThemes,
});

applyDirectives(app, [testIdDirective]);

app.mount("#app");
