import { ref } from "vue";
import { defineStore } from "pinia";
import KybService from "@/services/Kyb";

export const useKybFlowStore = defineStore("KybFlowStore", () => {
    const data = ref({
        company_type: null as unknown as string,
    });

    const changeData = (newData: any) => {
        data.value.company_type = newData;
    };

    async function getCompanyType() {
        const kybService = KybService.getInstance();
        const response = await kybService.getCompanyDetails();
        if (response.data.company_type) {
            data.value.company_type = response.data.company_type;
        }
    }

    function $reset() {
        data.value = { company_type: null as unknown as string };
    }

    return {
        data,
        changeData,
        getCompanyType,
        $reset,
    };
});
