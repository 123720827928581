import { ref } from "vue";
import { defineStore } from "pinia";
import { useAuthStore } from "@/stores/userAuth";
import KybService from "@/services/Kyb";
import type ICompanyDetails from "@/types/CompanyDetails";

export const useKybGeneralStore = defineStore("KybGeneralStore", () => {
    const kybService = KybService.getInstance();
    const authStore = useAuthStore();

    const data = ref({} as ICompanyDetails);

    const downloadedFile = ref([]);

    const phoneNumber = ref({
        country_code_id: "",
        phone_input: "",
    });

    data.value.legal_company_name = authStore?.companyData?.name;
    data.value.website = authStore?.companyData?.website ?? "";
    data.value.country = authStore?.companyData?.country ?? [];
    data.value.email = authStore?.userData?.email;

    const changeData = (newData: any) => {
        data.value = { ...data.value, ...newData };
    };

    async function fetchData() {
        try {
            const response = await kybService.getCompanyDetails();
            const fetchedData = await response.data;

            downloadedFile.value = fetchedData.certificates;

            data.value = {
                ...data.value,
                ...fetchedData,
                address_line_2: data.value.address_line_2
                    ? data.value.address_line_2
                    : "",
                certificates: "",
                email: fetchedData.company_email_address,
            };
        } catch (err: any) {
            const errors = err?.response?.data;
            console.log(errors);
        }
    }

    function $reset() {
        data.value = {} as ICompanyDetails;
        phoneNumber.value = {
            country_code_id: "",
            phone_input: "",
        };
        downloadedFile.value = [];
    }

    return {
        data,
        phoneNumber,
        downloadedFile,
        fetchData,
        changeData,
        $reset,
    };
});
