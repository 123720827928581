import { defineStore } from "pinia";
import type IQuestionnaireInfo from "@/types/QuestionnaireInfo";
import ProductService from "@/services/Product";
import { useAuthStore } from "@/stores/userAuth";
import handleError from "@/utils/handleError";

export const useSupplierQuestionnaireStore = defineStore(
    "SupplierQuestionnaire",
    {
        state: () => ({
            companyInfo: {
                num_of_skus: "",
                mov: "",
                annual_turnover: "",
                num_of_employees: "",
            },
            selectedCategories: [] as unknown as Array<string>,
            productCatalogues: [] as unknown as Array<any>,
            uploadedCatalogues: [] as unknown as Array<any>,
            productService: ProductService.getInstance(),
            authStore: useAuthStore(),
        }),
        actions: {
            addIngredientCategories(category: string) {
                this.selectedCategories.push(category);
            },

            removeIngredientCategories(removedCategory: string) {
                this.selectedCategories = this.selectedCategories.filter(
                    (category) => category !== removedCategory
                );
            },

            resetIngredientCategories() {
                this.selectedCategories = [];
            },

            changeCompanyInfo(updatedInfo: IQuestionnaireInfo) {
                this.companyInfo = { ...updatedInfo };
            },

            async listCatalogues() {
                try {
                    const supplierId = this.authStore.companyData.uuid;
                    const response = await this.productService.list(supplierId);
                    const { results } = await response.data;
                    this.uploadedCatalogues = results.reverse();
                } catch (err: any) {
                    const errors = err?.response?.data ?? null;
                    handleError(errors);
                }
            },
        },
    }
);
