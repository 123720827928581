import { defineStore } from "pinia";
import BrandService from "@/services/Brand";
import MySuppliersService from "@/services/MySuppliers";
import handleError from "@/utils/handleError";
import { omit } from "lodash";
import { formInitialData } from "@/forms/brands/Inquiry/StepOne/config";
import type IInquiry from "@/types/Inquiry";
import type {
    InquiryResponse,
    InquiryList,
    PaginatedExistingSuppliersListList,
    Ingredient,
} from "@/types/api/data-contracts";
import { ExistingSuppliersListStatusEnum } from "@/types/api/data-contracts";
import { formatPayloadDates, isAxiosError } from "@/utils";
import { INQUIRY_ICON_FALLBACK } from "@/constants";
import IngredientService from "@/services/Ingredient";
import { useAuthStore } from "@/stores/userAuth";

const brandService = BrandService.getInstance();
const mySuppliersService = MySuppliersService.getInstance();
const ingredientService = IngredientService.getInstance();

export const useInquiryStore = defineStore({
    id: "inquiryStore",
    state: () => {
        const authStore = useAuthStore(); // Instantiate the authStore
        const currency =
            authStore?.companyData?.currency?.toLocaleUpperCase() ?? "GBP";

        return {
            loading: false,
            data: [] as unknown as IInquiry[],
            ingredients: [] as unknown as IInquiry[],
            activeStep: 1,
            isDateFlexible: false,
            initialData: { ...formInitialData, currency },
            editData: null as unknown as InquiryResponse,
            existingSuppliersList:
                [] as unknown as PaginatedExistingSuppliersListList,
            documents: [],
            steps: [
                {
                    step: 1,
                    title: "Request Details",
                    description: "Provide information about the request",
                },
                {
                    step: 2,
                    title: "Shipping",
                    description:
                        "Provide shipping details and any other details",
                },
                {
                    step: 3,
                    title: "Verify & Submit",
                    description: "Verify your details and submit the request",
                },
            ],
        };
    },
    getters: {
        registeredExistingSuppliers: (state) =>
            state.existingSuppliersList.results?.filter(
                (suppliers) =>
                    suppliers.status ===
                    ExistingSuppliersListStatusEnum.Registered
            ) ?? [],
    },
    actions: {
        setData(payload: object, index: number = 0) {
            this.data[index] = {
                ...this.data[index],
                ...payload,
            };
        },
        setInitialData(data: InquiryResponse | InquiryList) {
            // @ts-ignore
            const deliveryAddress = data.delivery_address?.id
                ? // @ts-ignore
                  data.delivery_address?.id
                : data.delivery_address;

            const payload = {
                name: data.name,
                pricing_option: "unit_price",
                current_price: +data.current_unit_price,
                target_price: +data.target_unit_price,
                currency: data.currency,
                description: data.description,
                desired_packaging_format: data.desired_packaging_format,
                desired_packaging_format_is_flexible:
                    data.desired_packaging_format_is_flexible,
                quantity: data.quantity,
                unit_of_measurement: data.unit_of_measurement,
                allergen_requirements: data.allergen_requirements,
                allergen_declaration: data.allergen_declaration,
                diet_requirements: data.diet_requirements,
                dietary_certificate: data.dietary_certificate,
                forecast: data.forecast,
                current_supplier: data.current_supplier,
                logo_url: data.logo_url,
                existing_suppliers: data.existing_suppliers,
                frequency: data.frequency,
                is_recurring: data.is_recurring,
                desired_contract_length: data.desired_contract_length,
                first_order_delivery_date: "",
                due_date: "",
                delivery_address: deliveryAddress,
                supplier_countries: data.supplier_countries,
                is_delivery_date_flexible: data.is_delivery_date_flexible,
            };

            // @ts-ignore
            this.initialData = payload;
        },
        setInitialDataViaIngredientDetails(data: Ingredient | undefined) {
            const payload = {
                logo_url: data?.logo_url ?? INQUIRY_ICON_FALLBACK,
                name: data?.name ?? "",
                description: data?.description ?? "",
                pricing_option: "unit_price",
                current_price: data?.current_unit_price || null,
                currency: data?.currency ?? "",
                quantity: data?.quantity || null,
                unit_of_measurement: data?.unit_of_measurement ?? "",
                existing_suppliers: data?.existing_suppliers ?? [],
                allergen_requirements: data?.allergen_requirements ?? [],
                diet_requirements: data?.diet_requirements ?? [],
                allergen_declaration: data?.allergen_declaration ?? false,
                dietary_certificate: data?.dietary_certificate ?? false,
                current_supplier: data?.current_supplier ?? "",
                frequency: data?.frequency ?? "",
                supplier_countries: [],
                is_recurring: true,
                newIngredient: false,
                ingredient: data?.id,
            };

            // @ts-ignore
            this.initialData = payload;
        },
        populateMultipleIngredients(data: Ingredient[]) {
            data.forEach((ingredient) => {
                const payload = {
                    logo_url: ingredient?.logo_url ?? INQUIRY_ICON_FALLBACK,
                    name: ingredient?.name ?? "",
                    description: ingredient?.description ?? "",
                    pricing_option: "unit_price",
                    current_price: ingredient?.current_unit_price || null,
                    currency: ingredient?.currency ?? "",
                    quantity: ingredient?.quantity || null,
                    unit_of_measurement: ingredient?.unit_of_measurement ?? "",
                    existing_suppliers: ingredient?.existing_suppliers ?? [],
                    allergen_requirements:
                        ingredient?.allergen_requirements ?? [],
                    diet_requirements: ingredient?.diet_requirements ?? [],
                    allergen_declaration:
                        ingredient?.allergen_declaration ?? false,
                    dietary_certificate:
                        ingredient?.dietary_certificate ?? false,
                    current_supplier: ingredient?.current_supplier ?? "",
                    frequency: ingredient?.frequency ?? "",
                    supplier_countries: [],
                    is_recurring: true,
                    newIngredient: false,
                    ingredient: ingredient?.id,
                };
                // @ts-ignore
                this.ingredients.push(payload);
            });
        },
        setExistingSupplier(selectedSuppliers: string[]) {
            this.initialData.existing_suppliers = selectedSuppliers;
        },
        // TODO: Add getters and actions to fetch and store brand + supplier inquiry here
        async initInquiry(payload: any, index: number): Promise<boolean> {
            try {
                this.loading = true;
                let response = null;
                let ingredientUUID = null;
                let ingredientID = null;

                // removing existing_supplier from the payload if null
                const structuredPayload = payload.existing_suppliers.length
                    ? payload
                    : omit(payload, [
                          "existing_suppliers",
                          "existing_supplier_name",
                      ]);

                if (typeof this.data[index] === "undefined") {
                    if (payload.newIngredient) {
                        const ingredientResponse =
                            await ingredientService.createIngredient(
                                // @ts-ignore
                                payload
                            );

                        const { data } = ingredientResponse;
                        ingredientUUID = data.uuid;
                        ingredientID = data.id;
                    }

                    const createInquiryPayload = omit(
                        structuredPayload,
                        "delivery_address"
                    );
                    response = await brandService.createInquiry({
                        ...createInquiryPayload,
                        ingredient: ingredientID,
                    });
                } else {
                    const newPayload = {
                        ...this.data[index],
                        ...structuredPayload,
                    };

                    formatPayloadDates(newPayload);

                    if (payload.newIngredient) {
                        const ingredientResponse =
                            await ingredientService.editIngredient(
                                // @ts-ignore
                                this.data[index].ingredientUUID,
                                payload
                            );

                        const { data } = ingredientResponse;
                        ingredientUUID = data.uuid;
                    }
                    response = await brandService.updateInquiry(
                        this.data[index].uuid,
                        newPayload
                    );
                }
                const data = await response.data;

                this.data[index] = {
                    ...this.data[index],
                    ...omit(data, "delivery_address"),
                    ...structuredPayload,
                    ingredientUUID,
                };
                this.loading = false;
                return true;
            } catch (error: any) {
                this.loading = false;
                const displayToast = isAxiosError(error);
                handleError(error, displayToast);
                return false;
            }
        },
        async publishInquiry(): Promise<boolean> {
            try {
                this.loading = true;
                this.data.forEach(async (data: any) => {
                    await brandService.publishInquiry(data.uuid);
                });
                this.loading = false;
                return true;
            } catch (error: any) {
                this.loading = false;
                const displayToast = isAxiosError(error);
                handleError(error, displayToast);
                return false;
            }
        },
        async deleteInquiry(index: number): Promise<boolean> {
            try {
                this.loading = true;
                await brandService.deleteInquiry(this.data[index].uuid);
                this.data = this.data.filter((data, idx) => idx !== index);
                this.loading = false;
                return true;
            } catch (err: any) {
                this.loading = false;
                const errors = err?.response?.data ?? null;
                handleError(errors);
                return false;
            }
        },
        /**
         * set inquiry data for edit mode
         */
        initializeEditData(inquiry: InquiryResponse) {
            this.editData = inquiry;
        },
        /**
         * fetch inquiry icon
         */
        async fetchInquiryIcon(title: string) {
            const response = await brandService.fetchInquiryIcon({ title });
            const { icon } = response.data;
            return icon;
        },
        /**
         * fetch existing suppliers
         */
        async fetchMySuppliers(userId: string) {
            const response = await mySuppliersService.listSuppliers(userId);
            this.existingSuppliersList = response.data;
        },
        /**
         * fetch inquiries documents
         */
        async fetchInquiryDocuments(inquiryId: string) {
            const response = await brandService.getInquiryDocuments(inquiryId);
            this.documents = response.data.results;
        },
        /**
         * delete inquiries document
         */
        async deleteInquiryDocuments(inquiryId: string, documentId: string) {
            await brandService.deleteInquiryDocument(inquiryId, documentId);
            await this.fetchInquiryDocuments(inquiryId);
        },
        /**
         * upload inquiries document
         */
        async uploadInquiryDocuments(inquiryId: string, document: FormData) {
            await brandService.uploadInquiryDocument(inquiryId, document);
            await this.fetchInquiryDocuments(inquiryId);
        },
        /**
         * Partially update an inquiry using a PATCH request.
         * @param {string} inquiryId
         * @param {PatchedInquiry} payload
         */
        async patchInquiry(inquiryId: string, payload: object) {
            await brandService.patchInquiry(inquiryId, payload);
        },

        /**
         * Full update an inquiry using PUT request.
         * @param {string} inquiryId
         * @param {InquiryUpdate} payload
         */
        async updateInquiry(inquiryId: string, payload: object) {
            await brandService.updateInquiry(inquiryId, payload);
        },
        async emptyStore() {
            this.data = [];
        },
    },
});

export default { useInquiryStore };
