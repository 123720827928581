import { ref } from "vue";
import { defineStore } from "pinia";
import KybService from "@/services/Kyb";
import type IAuthorisedUser from "@/types/AuthorisedUser";

export const useKybAuthorisationStore = defineStore(
    "KybAuthorisationStore",
    () => {
        const kybService = KybService.getInstance();

        const data = ref({} as IAuthorisedUser);

        const downloadedFile = ref("");

        const phoneNumber = ref({
            country_code_id: "",
            phone_input: "",
        });

        const changeData = (newData: any) => {
            data.value = { ...data.value, ...newData };
        };

        function checkValueExist(authorisedUserData: object) {
            const checkedData = {} as any;

            Object.keys(authorisedUserData).forEach((key: string) => {
                if (
                    authorisedUserData[
                        key as keyof typeof authorisedUserData
                    ] as string
                ) {
                    checkedData[key as keyof typeof checkedData] =
                        authorisedUserData[
                            key as keyof typeof authorisedUserData
                        ];
                } else {
                    checkedData[key] = "";
                }
            });

            return checkedData;
        }

        async function fetchData() {
            try {
                const response = await kybService.getAuthorisedUser();
                const fetchedData = await response.data;
                let checkedData = {} as any;

                checkedData = checkValueExist(fetchedData);

                data.value = { ...data.value, ...checkedData };
                downloadedFile.value = fetchedData.identification_image;
            } catch (err: any) {
                const errors = err?.response?.data;
                console.log(errors);
            }
        }

        function $reset() {
            data.value = {} as IAuthorisedUser;
            phoneNumber.value = {
                country_code_id: "",
                phone_input: "",
            };
            downloadedFile.value = "";
        }

        return {
            data,
            fetchData,
            phoneNumber,
            downloadedFile,
            changeData,
            $reset,
        };
    }
);
