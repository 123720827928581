import { defineStore } from "pinia";

import type { QuoteDetail, QuoteRetrieve } from "@/types/api/data-contracts";

import { getValidBasketQuotes, getLowestDelayInQuotes } from "@/utils";
import { useRoute } from "vue-router";

export const useBasketStore = defineStore({
    id: "basketStore",
    state: () => ({
        storage: [] as unknown as QuoteRetrieve[],
        checkoutQuote: [] as unknown as QuoteRetrieve[],
    }),
    actions: {
        basketStorageContainsQuote(quote: {
            [key: string]: any;
            uuid: string;
        }): boolean {
            return this.checkoutQuote.some((q) => quote.uuid === q.uuid);
        },
        basketStorageContainsDuplicateQuote(
            originalQuoteUuid: string
        ): boolean {
            return this.storage.some(
                (q) => originalQuoteUuid === q.original_quote
            );
        },
        confirmOrder() {
            const newStorage = this.storage.filter(
                (quote) => !this.basketStorageContainsQuote(quote)
            );
            this.storage = newStorage;
            localStorage.setItem("basket", JSON.stringify(newStorage));
            localStorage.removeItem("checkoutQuote");
        },
        deleteQuoteFromBasket(uuid: string) {
            const newStorage = this.storage.filter(
                (quote) => quote.uuid !== uuid
            );
            this.storage = newStorage;
            localStorage.setItem("basket", JSON.stringify(newStorage));
        },
        deleteReOrderQuoteFromBasket(originalQuoteUuid: string) {
            const newStorage = this.storage.filter(
                (quote) => quote.original_quote !== originalQuoteUuid
            );
            this.storage = newStorage;
            localStorage.setItem("basket", JSON.stringify(newStorage));
        },
        addQuoteToBasket(quote: QuoteRetrieve) {
            this.storage.push(quote);
            localStorage.setItem("basket", JSON.stringify(this.storage));
        },
        addCheckoutQuote(quote: QuoteRetrieve[]) {
            this.checkoutQuote = quote;
            localStorage.setItem(
                "checkoutQuote",
                JSON.stringify(this.checkoutQuote)
            );
        },
        /**
         * Removes expired quotes from the basket and updates the local storage and internal state accordingly.
         * @param {QuoteDetail[]} basketQuotes - An array of quote details currently in the basket.
         */
        removeExpiredQuotesFromBasket(basketQuotes: QuoteDetail[]) {
            const validQuotes = getValidBasketQuotes(basketQuotes);
            if (validQuotes.length === basketQuotes.length) return;

            localStorage.setItem("basket", JSON.stringify(validQuotes));

            this.storage = validQuotes as unknown as QuoteRetrieve[];
        },
        getDataFromLS() {
            const basketStorageFromLS = localStorage.getItem("basket");
            const basketStorage = JSON.parse(basketStorageFromLS ?? "[]");
            this.storage = basketStorage;
            this.removeExpiredQuotesFromBasket(basketStorage);

            const checkoutQuoteFromLS = localStorage.getItem("checkoutQuote");
            const checkoutQuote = JSON.parse(checkoutQuoteFromLS ?? "[]");
            this.checkoutQuote = checkoutQuote;
        },
        resetQuoteBasket() {
            localStorage.removeItem("basket");
        },
        getLowestPaymentDelay() {
            const route = useRoute();
            const isContract = !!route.query.contractUuid;

            const checkoutQuoteData = isContract
                ? this.checkoutQuote
                : this.storage;

            return getLowestDelayInQuotes(checkoutQuoteData);
        },
    },
    getters: {
        basket: (state) => state.storage,
    },
});

export default { useBasketStore };
