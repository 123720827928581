import { ref } from "vue";
import { defineStore } from "pinia";
import type IPerson from "@/types/Person";

export const useKybShareholdersStore = defineStore(
    "KybShareholdersStore",
    () => {
        const data = ref({
            controlling_person: {} as IPerson,
            ceo: {} as IPerson,
        });

        const changeData = (newData: any) => {
            data.value = { ...data.value, ...newData };
        };

        function $reset() {
            // @ts-ignore
            data.value = {} as unknown;
        }

        return {
            data,
            changeData,
            $reset,
        };
    }
);
