import { defineStore } from "pinia";
import { computed, ref, type Ref } from "vue";
import handleError from "@/utils/handleError";
import MarketFinanceService from "@/services/MarketFinance";
import type { CompanyDetailsRetrieve } from "@/types/api/data-contracts";

export const useKriyaStore = defineStore("kriyaStore", () => {
    /**
     * defines loading state for the component when data is loading
     */
    const loading = ref(false);
    /**
     * defines current active step of multi-step kyb application
     */
    const activeStep = ref(1);
    /**
     * defines kriya company details
     */
    const companyDetails = ref() as unknown as Ref<CompanyDetailsRetrieve>;
    const steps = ref([
        {
            step: 1,
            title: "Company Details",
            description: "Provide information about the request",
        },
        {
            step: 2,
            title: "Directors",
            description: "Provide shipping details and any other details",
        },
        {
            step: 3,
            title: "Shareholders",
            description: "Verify your details and submit the request",
        },
    ]);

    const marketFinanceService = MarketFinanceService.getInstance();

    /**
     * Kriya application status is returned, if application status is null "Not Started" is returned
     */
    const applicationStatus = computed(
        () => companyDetails.value?.decision_status ?? "Not Started"
    );

    const patchCompanyDetails = (payload: CompanyDetailsRetrieve) => {
        companyDetails.value = { ...companyDetails.value, ...payload };
    };

    const updateCompanyDetails = async (
        payload: object,
        updateState = false
    ): Promise<boolean> => {
        try {
            await marketFinanceService.updateCompanyDetails(payload);
            if (updateState) {
                patchCompanyDetails(payload as CompanyDetailsRetrieve);
            }
            return true;
        } catch (error: any) {
            handleError(error);
            return false;
        }
    };

    const fetchCompanyDetails = async () => {
        try {
            loading.value = true;
            const response = await marketFinanceService.getCompanyDetails();
            companyDetails.value = response.data;
            loading.value = false;
        } catch (error: any) {
            loading.value = false;
            handleError(error);
        }
    };

    const uploadDirectors = async (payload: object) => {
        try {
            const response = await marketFinanceService.uploadDirectors(
                payload
            );
            return response.data;
        } catch (error: any) {
            handleError(error);
            throw error;
        }
    };

    const updateDirector = async (payload: object, id: string) => {
        try {
            const response = await marketFinanceService.updateDirector(
                payload,
                id
            );
            return response.data;
        } catch (error: any) {
            handleError(error);
            return false;
        }
    };

    const deleteDirector = async (id: string) => {
        try {
            await marketFinanceService.deleteDirector(id);
        } catch (error: any) {
            handleError(error);
        }
    };

    const fetchDirectors = async () => {
        try {
            const response = await marketFinanceService.getDirectors();
            return response.data;
        } catch (error: any) {
            handleError(error);
            return false;
        }
    };

    const uploadShareholders = async (payload: object) => {
        try {
            const response = await marketFinanceService.uploadShareholders(
                payload
            );
            return response.data;
        } catch (error: any) {
            handleError(error);
            return false;
        }
    };

    const updateShareholder = async (payload: object, id: string) => {
        try {
            const response = await marketFinanceService.updateShareholder(
                payload,
                id
            );
            return response.data;
        } catch (error: any) {
            handleError(error);
            return false;
        }
    };

    const fetchShareholders = async () => {
        try {
            const response = await marketFinanceService.getShareholders();
            return response.data;
        } catch (error: any) {
            handleError(error);
            return false;
        }
    };
    const deleteShareholders = async (id: string) => {
        try {
            await marketFinanceService.deleteShareholders(id);
        } catch (error: any) {
            handleError(error);
        }
    };

    const resetStore = () => {
        loading.value = false;
        activeStep.value = 1;
        companyDetails.value = null as unknown as CompanyDetailsRetrieve;
    };

    return {
        steps,
        loading,
        activeStep,
        resetStore,
        companyDetails,
        deleteDirector,
        updateDirector,
        fetchDirectors,
        uploadDirectors,
        applicationStatus,
        updateShareholder,
        fetchShareholders,
        deleteShareholders,
        uploadShareholders,
        fetchCompanyDetails,
        updateCompanyDetails,
    };
});

export default { useKriyaStore };
