import * as Sentry from "@sentry/vue";

import { useAuthStore } from "@/stores/userAuth";
import type { UserContext } from "@/types";

/**
 * Builds the user context based on the authentication state.
 * @returns {Object} The user context for Sentry.
 */
export const buildUserContext = (): UserContext => {
    const authStore = useAuthStore();

    let userContext = {};

    if (authStore.isLoggedIn) {
        const userType = authStore.isBrand ? "Brand" : "Supplier";
        const isMasqueradeMode = authStore.masqueradeMode;

        userContext = {
            uuid: authStore.getUserUUID,
            id: authStore.userData.id,
            email: authStore.userData.email,
            type: userType,
            masquerade: isMasqueradeMode,
            loggedIn: "True",
        };

        Sentry.setTag("userType", userType);
        Sentry.setTag("masquerade", isMasqueradeMode);
    } else {
        userContext = {
            isLoggedIn: "False",
            masquerade: "False",
        };
    }

    return userContext as UserContext;
};
